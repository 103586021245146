<template>
    <section class="template2">      <!-- MODIFY -->

        <div class="row d-flex flex-row justify-content-end"> 
            <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">template2</li>   <!-- MODIFY -->
                    <li class="breadcrumb-item active" aria-current="page"><router-link class="" v-bind:to="'/g1/first'+'?t='+$root._SYS().add_v()"  >first</router-link></li>  
                </ol>
            </nav>
        </div>

        <div class="row"> 

            <div class="col-xl-12 col-lg-12 col-md-12 grid-margin stretch-card">
                <div class="card">
                    <div class="card-body">

                        <h4>_route_= {{ this.$store.state._route_ }}</h4>



                        <router-link class="pl-4 pb-4" v-bind:to="'/g1/first/'+'?t='+$root._SYS().add_v()"  >first</router-link>

                    </div> 

                    <div class='card-body'  v-if='$store.state.system[cdata.system._route_].is_all_fin_for_this_page' >   <!-- index.js  is_required_stages -->

                        template2

                        <div class="d-flex flex-column align-items-start">
                            <div><b>[auth]</b></div> 
                            <div>rid: {{ $store.state.state_tables.state_auth.r_auth['rid'] }}</div> 
                            <div>mysql_v: {{ $store.state.state_tables.state_auth.r_auth['mysql_v'] }}</div>
                            <div>------------------</div>
                            <div><b>[layouts]</b></div>
                            <div>Layouts version_timestamp: {{ $store.state.state_tables.state_layouts.json_layouts.version_timestamp }}</div> 


                            <div>layouts:</div>

                            <div  v-for="(item, index) in $store.state.state_tables.state_layouts.json_layouts.layouts['class_'+form.classnum][form.lid]" :key="index">
                                <div>-{{ index }} | {{ item }}  </div>  
                            </div>

                            
                                <div>json_links:</div>
                                <div  v-for="(item, index) in form.json_links" :key="index">
                                    <div>N{{index}}_<b>{{ item.desk_code }}</b>[{{ item.desk_name }}]_{{ item.version_timestamp }}_<b>{{ item.desk_id }}</b> </div>  
                                </div>
                            
                        </div>


                        <div class="mt-5">
                           level_props__solve_limit version: {{state.game_settings.level_props__solve_limit.version_timestamp}}
                        </div>

<!--                        <b-button variant="primary" class=" btn  " v-on:click="move_to('/g1/first/')" v-bind:disabled='cdata.system.buttons.button1.is_button_blocked'>first (common)</b-button>
                     
                        <b-button variant="primary" class=" btn" v-on:click="test_btn" v-bind:disabled='cdata.system.buttons.common.is_button_blocked'>test_btn (common)</b-button>
                        <b-button variant="primary" class=" btn" v-on:click="test_btn" v-bind:disabled='cdata.system.buttons.button1.is_button_blocked'>test_btn (button1)</b-button>
                        

                        test
                        <b-button   class="btn-fw ml-4 mb-2 mt-3 solvebutton"   variant="primary" style=" min-width: unset;" :disabled="cdata.system.buttons.common_without_symmary.is_button_blocked" @click="show_answers">Проверить!</b-button> 
                        test-->
                                                

                    </div> 
                    <div v-else>Loading...</div>



                </div>





            </div>   

        </div>





    </section>
</template>

<script>
    import Vue from 'vue'
    import { mapGetters } from 'vuex'
            var cdata = {//Если не указать - не всключиться реактивность 
                /* COPYPASTE MODIFY */
                system: {
                    _route_: "g1_template2", /* MODIFY */
                    is_section: {// store/index  -> is_required_sections
                        center: false, /* СЕКЦИИ НА СТРАНИЦЕ MODIFY  v-if='cdata.system.is_section.center'   */
                    },
                    is_stage: {// store/index  -> is_required_stages
                        center: false,
                    },
                    cnf: {},
                    buttons: {
                        common_without_symmary: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        //0: "r_auth", //r_auth  
                                        0: "json_version", //r_auth 
                                        1: "events_list", //r_auth 
                                        //2: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },
                        common: {
                            is_button_blocked: true,
                            button_blocking_exeption_list: {}, //ДЛЯ ДЕФОЛТНЫХ КНОПОК!!!
                        },
                        button1: {
                            is_button_blocked: true,
                            button_blocking_exeption_list:
                                    {
                                        0: "r_auth", //r_auth 
                                        1: "a_test", // 
                                        2: "json_layouts", // 
                                        //3: "json_layout_xxxx__pos_xx", НЕТ, просто не добавлять на эту страницу динамические таблицы!!!
                                    }
                        },

                    },

                },
                errors: {
                    is_errors: false,
                    actions_error: {},
                },
                business_logic: {

                },

            }

    export default {
        name: 'template2', /* MODIFY */
        data() {
            return {
                cdata: cdata,
            };
        },

        created() { },

        mounted() {
            this.$store.dispatch('_system/vuex_set__route_', this.cdata.system._route_);
            this.$root._PAGE().on_mounted(this.$store, this.cdata); //обнулить флажки

            //IF NEEDED 

            setTimeout(() => {
                this.$store.dispatch('_system/vuex_set__is_mounted_execute', {'is_mounted_execute': true, '_route_': this.cdata.system._route_ });
                this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            }, 300);
            //this.cdata.system.is_mounted_execute = true;    
            //this.$store.dispatch('_system/vuex_set__cnt_plus_plus', {});
            
            
            this.$store.dispatch('_json/vuex_set__json_data__back', this.$root._LOAD().json___level_props__solve_limit__back(this.$store, this.cdata));
        },
        computed: {

            /* COPYPASTE */
            ...mapGetters(['__blocked_tables', '__is_required_sections', '__is_required_stages', '__tables', '__is_errors']),
            _system() {
                let debug = {};
                try {
                    debug['tables'] = this.__tables(this.cdata['system']['_route_']);
                    debug['is_errors'] = this.__is_errors(this.cdata['system']['_route_']);
                    debug['blockings'] = this.__blocked_tables(this.cdata['system']['_route_']);
                    debug['sections'] = this.__is_required_sections(this.cdata['system']['_route_']);
                    debug['stages'] = this.__is_required_stages(this.cdata['system']['_route_']);
                } catch (ex) {
                    console.log(ex);
                }
                return debug;
            },
            /* MODIFY */
            state() {
                let state = {};
                try {
                    /* MODIFY */ //ВЫБРАТЬ используемые МОДУЛИ --> подгрузятся ТАБЛИЦЫ в них
                    state['test'] = this.$store.state['state_tables'].state_test;/* MODIFY */
                    state['auth'] = this.$store.state['state_tables'].state_auth;/* MODIFY */
                    state['layouts'] = this.$store.state['state_tables'].state_layouts;/* MODIFY */

                    state['desks'] = this.$root._LAYOUT().add_desks_to_state_desks(this.$store, this.cdata, this.$store.state.is_loaded_stages.dynamic_desks_ready);

                    state['game_settings'] = this.$store.state['state_tables'].state_game_settings;/* MODIFY */

                } catch (ex) {
                    console.log(ex);
                }

                return state;
            },

            form() {
                let form = {};// form.classnum


                form['cnt_loaded'] = this.$store.state.cnt_loaded;
                form['is_all_stage_fin'] = this.$store.state.is_all_stage_fin;
                form['classnum'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('classnum') ? this.$store.state.state_tables.state_auth.r_auth['classnum'] : "-1";
                form['lid'] = this.$store.state.state_tables.state_auth.r_auth.hasOwnProperty('lid') ? this.$store.state.state_tables.state_auth.r_auth['lid'] : "-1";
               
                 if (
                        this.$store.state.is_loaded_stages.layouts === "fin" &&
                        this.$store.state.is_loaded_stages.auth === "fin" &&
                        this.$store.state.is_loaded_stages.initial_shift === "fin" 
                        )
                { //падает, если неподгружены данные по auth и layouts 
                    let classnum = this.$store.state.state_tables.state_auth.r_auth['classnum'];
                    let lid = this.$store.state.state_tables.state_auth.r_auth['lid']
                    //console.log(classnum); 
                    //console.log(lid); 
                    //console.log(this.$store.state.state_tables.state_layouts.json_layouts); 
                    //console.log(this.$store.state.state_tables.state_layouts.json_layouts.layouts['class_' + classnum][lid]['json_links']); 
                    form['json_links'] = this.$store.state.state_tables.state_layouts.json_layouts.layouts['class_' + classnum][lid]['json_links'];
                } else
                {
                    form['json_links'] = {};
                } 
                 

                return form;
            }
            /* #ODIFY */
            /* #OPYPASTE */

        },

        watch: {

            /* COPYPASTE на каждое изменение при работе с таблицей два вызова на начало и завершение*/
            "form.cnt_loaded": {
                handler: function () {

                    
                    
                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после выполнения всех стадий, на любое действие с таблицами
                    if (this.$store.state.is_all_stage_fin)
                    { 
                        //console.log("EXECUTE AFTER ALL STAGE LOAD FIN [" + this.cdata.system._route_ + "]");
                    }  


                    //ВЫПОЛНИТСЯ РАЗОВО - проверяем готовы ли стадии, для текущей страницы 
                    if (this.$store.state.system[this.cdata.system._route_].is_mounted_execute && !this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page) {
                        //проверить завершение всех стадий для страницы (срабатывал раньше времени)
                        //set cdata.system.is_stages_fin_for_THIS_page
                        // console.log("CALC is_stages_fin_for_THIS_page");
                        if (this.$root._SYS().check_is_stages_fin_for_this_page(this.$store, this.cdata))
                        {
                            this.$store.dispatch('_system/vuex_set__is_all_fin_for_this_page',   {'is_all_fin_for_this_page': true, '_route_': this.cdata.system._route_ });
                            this.$root._SYS().page_v_update(this.$store, this.cdata);
                            
                            
                            console.log("REGISTER ALL STAGE LOAD FIN - FOR PAGE [" + this.cdata.system._route_ + "]");
                        }


                    }

 
                    //ПОСТОЯННО ВЫПОЛНЯЕТСЯ после загрузки всех стадий для страницы, на любое действие с таблицами
                    if (this.$store.state.system[cdata.system._route_].is_all_fin_for_this_page)
                    {
                        //просто постоянно перещитываем счетчики и блокровки кнопок!
                        this.$root._SYS().watch_force_called_on_mounted_execute(this.$store, this.cdata, true);
                    }



                },
                deep: true, //чтобы детектил изменения вложенных в объекто элементов
                immediate: true, //При передаче immediate: true в опциях, коллбэк будет вызываться незамедлительно с текущим значением отслеживаемого выражения:
            },

        },

        methods: {
            
            show_answers: async function (event) {
                await this.$store.dispatch('_data/vuex_update__multi_a_data__ls_or_back', this.$root._UPDATE().on_test_button__multi_ls_and_back(this.$store, this.cdata));
            },

            move_to: function (new_route) { 
               this.$root._PAGE().move_to(this.$store, this.cdata, new_route); 
            },
            //load переименовать 

            test_btn: async function () {

                console.log("TEST BTN");

                await this.$store.dispatch('_data/vuex_set__a_data__ls_or_back', this.$root._LOAD().a_test__ls_or_back(this.$store,this.cdata));

                console.log("TEST BTN FIN");
            },

        }


    }
</script>

<style scoped>
    @media (max-width: 991px){
        .page-body-wrapper {
            padding-top: 0px !important;
        }
    }
</style>
